.col {
  padding: 0 1em 1em 1em;
  text-align: center;
}

.col .icon-card {
  width: 320px;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 5px;
  /* box-shadow: 0 0 35px rgba(0, 0, 0, 0.12); */
  /* cursor: pointer; */
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
/* 
.col .icon-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#0dcaf0, rgb(255, 23, 131));
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
} */

.col .icon-card h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.col .icon-card p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.col .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 50px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* transition: 0.5s; */
  z-index: 3;
}

.col .icon-wrapper i {
  font-size: 50px;
}

/* .col .icon-card:hover:after {
  top: 0%;
} */

.icon-card .icon-wrapper {
  background-color: #1f194c;
  border: 5px solid #0dcaf0;
  color: white;
}

/* .col .icon-card:hover .icon-wrapper {
  color: #0dcaf0;
}

.col .icon-card:hover h3 {
  color: #ffffff;
}

.col .icon-card:hover p {
  color: #f0f0f0;
} */
