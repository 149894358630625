button {
    border: 0;
    padding: 0;
    font-family: inherit;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }
  
  .navbar {
    position: relative;
    z-index: 102;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: #191919;
    color: #f9f9f9;
    box-sizing: border-box;
  }
  
  .navbar-overlay {
    position: fixed;
    z-index: 103;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  
  body.open .navbar-overlay {
    visibility: visible;
    opacity: 1;
  }
  
  .navbar-burger {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 64px;
    height: 100%;
    padding: 0;
  }
  
  .navbar-title {
    margin: 0;
    font-size: 35px;
    line-height: 20px;
  }
  
  .navbar-title > span {
    font-size: 16px;
    font-style: italic;
  }
  .navbar-menu {
    position: fixed;
    z-index: 104;
    top: 0;
    left: 0;
    translate: -100% 0;
    width: 200px;
    height: 100%;
    padding: 20px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #000;
    visibility: hidden;
    transition: 0.3s;
  }
  
  body.open .navbar-menu {
    translate: 0 0;
    visibility: visible;
  }
  
  .navbar-menu > button {
    color: white;
    background: transparent;
    text-transform: uppercase;
    font-size: 18px;
    height: 60px;
  }
  
  .navbar-menu > button:hover {
    border-top: 10px solid #00b2f2;
    transition: 0.5s;
  }
  
  @media only screen and (min-width: 800px) {
    .navbar {
      justify-content: space-between;
      padding: 0 10% 0 10%;
    }
    .navbar-overlay {
      display: none;
    }
    .navbar-burger {
      display: none;
    }
    .navbar-menu {
      position: static;
      translate: 0 0;
      width: auto;
      background: transparent;
      flex-direction: row;
      visibility: visible;
    }
  }