* {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  margin: 0;
  /* font-family: "Montserrat"; */
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

/* Marketplace */

.sectione-one {
  position: absolute;
  left: 5%;
  top: 15%;
  text-align: center;
}

.sectione-one .title {
  color: #00b2f2;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.sectione-one img {
  height: 50px;
  width: 50px;
}
.sectione-one .title .style-one {
  color: #f9f9f9;
  font-weight: 500;
}

.sectione-one .title .style-two {
  color: #000;
  background: white;
  padding: 5px 30px 5px 30px;
  line-height: 60px;
}

.sectione-two {
  background: #000;
  height: auto;
  width: 100%;
  padding: 8% 0% 8% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectione-two .container {
  background: #f9f9f9;
  height: auto;
  width: 80%;
  padding: 1% 8% 2% 8%;
  border-radius: 10px;
}

.sectione-two .container .form {
  display: flex;
  flex-direction: column;
}
.separation {
  margin-top: 30px;
}
.sectione-two .container .form button {
  cursor: pointer;
  background: #00b2f2;
  color: #f9f9f9;
  border: 0;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  font-weight: 600;
  letter-spacing: 2px;
}

.sectione-three {
  position: relative;
  background: #fff;
  height: auto;
  width: 100%;
  padding: 5% 8% 5% 8%;
  text-align: center;
}
/* .sectione-three h1 {
  letter-spacing: 8px;
  font-weight: 700;
  font-size: 32px;
  margin: 10px;
} */

.sectione-three p {
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}
.divider {
  padding: 0 50px 0 50px;
}
.container-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  position: relative;
  width: 350px;
  height: 200px;
  padding: 5px 5px 0px 5px;
  background: #020623;
}

.card .card-title {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  bottom: 0;
  right: 0;
  left: 0;
}

.card .card-title h1 {
  font-weight: 500;
}

.container-list {
  background: #99e1fa;
  width: 100%;
  min-height: 350px;
  height: auto;
  margin-top: -80px;
  padding: 25% 5% 16% 5%;
}

.container-list h1 {
  color: #020623;
  letter-spacing: 5px;
}

.container-list-cards {
  display: grid;
  grid-auto-columns: minmax(10px, auto);
  grid-template-columns: repeat(1, auto);
  justify-content: center;
  grid-gap: 50px;
}

.container-list-cards .card {
  background: #fff;
  width: 280px;
  height: 260px;
  padding: 0;
}

.container-list-cards .card .image {
  position: relative;
  width: 100%;
  height: 60%;
  padding: 5px;
  background: #020623;
}

.container-list-cards .card .image img {
  width: 100%;
  height: 100%;
}

.container-list-cards .card .image h4 {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  padding: 5px;
  margin: 0;
  bottom: 5px;
  left: 5px;
}

.container-list-cards .card .image h5 {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  padding: 5px;
  margin: 0;
  top: 5px;
  right: 5px;
}
.container-list-cards .card .description {
  position: relative;
  width: 100%;
  height: 40%;
  padding: 5px;
}
.container-list-cards .card .description .text {
  position: absolute;
  color: #00b2f2;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  padding: 5px;
  margin: 0;
  top: 5px;
  left: 10px;
}

.container-list-cards .card .description .text .text-two {
  color: #020623;
  font-size: 16px;
  font-weight: 600;
}

.container-list-cards .card .description .text .text-three {
  color: #020623;
  font-size: 14px;
  font-weight: bold;
}

.container-list-cards .card .description .box {
  position: absolute;
  background: #020623;
  color: #fff;
  font-size: 18px;
  padding: 5px;
  margin: 0;
  bottom: 10px;
  right: 0px;
}

.container-offers {
  position: relative;
  width: 100%;
  height: auto;
  background: #43484e;
  padding: 5%;
  text-align: center;
}

/* .container-offers h1 {
  color: #fff;
  margin: 0;
} */
.container-offers .box {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  width: auto;
  margin-top: 2%;
}

.container-offers .box .card {
  background: #fff;
  width: 280px;
  height: 260px;
  padding: 0;
}

.container-offers .box .card .image {
  position: relative;
  width: 100%;
  height: 60%;
  padding: 5px;
  background: #020623;
}

.container-offers .box .card .image img {
  width: 100%;
  height: 100%;
}

.container-offers .box .card .image h4 {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  padding: 5px;
  margin: 0;
  bottom: 5px;
  left: 5px;
}

.container-offers .box .card .image h5 {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  padding: 5px;
  margin: 0;
  top: 5px;
  right: 5px;
}
.container-offers .box .card .description {
  position: relative;
  width: 100%;
  height: 40%;
  padding: 5px;
}
.container-offers .box .card .description .text {
  position: absolute;
  color: #00b2f2;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  padding: 5px;
  margin: 0;
  top: 5px;
  left: 10px;
}

.container-offers .box .card .description .text .text-two {
  color: #020623;
  font-size: 16px;
  font-weight: 600;
}

.container-offers .box .card .description .text .text-three {
  color: #020623;
  font-size: 14px;
  font-weight: bold;
}

.container-offers .box .card .description .box {
  position: absolute;
  background: #020623;
  color: #fff;
  font-size: 18px;
  padding: 5px;
  margin: 0;
  bottom: 10px;
  right: 0px;
}

.sectione-four {
  background: #fff;
  height: auto;
  width: 100%;
  padding: 8%;
  text-align: center;
}

/* .sectione-four h1 {
  letter-spacing: 8px;
  font-size: 32px;
  margin: 10px;
} */

.sectione-four p {
  font-size: 18px;
  margin: 0;
}

.sectione-five {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("./images/happy-family-sitting-on-couch-in-living-room.jpg");
  width: 100%;
  height: auto;
  background-position: 30% 30%;
  padding: 5% 8% 4% 8%;
  text-align: center;
}

.sectione-five h1 {
  letter-spacing: 8px;
  font-size: 32px;
  color: white;
  margin: 10px;
}

.footer {
  width: 100%;
  height: auto;
  padding: 10px;
  background: #000;
  text-align: center;
}

.footer h3 {
  text-align: center;
  color: white;
}
@media only screen and (min-width: 800px) {
  .sectione-one {
    left: 15%;
    top: 30%;
  }
  .sectione-one .title {
    font-size: 40px;
  }
  .sectione-one img {
    height: 100px;
    width: 100px;
  }
  .sectione-two .container .form {
    display: grid;
    grid-auto-columns: minmax(15rem, auto);
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
  }
  .separation {
    display: none;
  }
  .sectione-two .container .form button {
    margin-top: 0px;
    height: auto;
  }

  .divider {
    padding: 0 150px 0 150px;
  }

  .container-cards {
    flex-direction: row;
  }

  .card {
    position: relative;
    width: 400px;
    height: 250px;
    padding: 5px 5px 0px 5px;
    background: #020623;
  }

  .container-list {
    padding: 8% 8% 5% 8%;
  }

  .container-list-cards {
    grid-template-columns: repeat(2, auto);
    justify-content: space-around;
  }
  .container-offers {
    width: 100%;
    padding: 5% 20% 5% 20%;
  }
  .container-offers .box {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1200px) {
  .container-list-cards {
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
  }
}

.btn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bn54 {
  position: relative;
  outline: none;
  text-decoration: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  height: 45px;
  width: 130px;
  opacity: 1;
  background-color: #000000;
}

.bn54 .bn54span {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.bn54:hover {
  animation: bn54rotate 0.7s ease-in-out both;
}

.bn54:hover .bn54span {
  animation: bn54storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}
.bn54:disabled {
  cursor: not-allowed;
}
@keyframes bn54rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes bn54storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}